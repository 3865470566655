<template>
  <Pivot
    toolbar
    report="https://cdn.webdatarocks.com/reports/report.json"
    width="100%"
    height="100%"
  />
</template>

<script>

export default {

  data() {
    return {
      toolbar: true
    };
  },

  methods: {
 
  },


};
</script>
